
* {
  box-sizing: border-box;
  scrollbar-width: thin;
}

mark {
  background-color: unset;
}

body {
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  user-select: none;
  background-color:rgb(243, 243, 243);
}

.material-icons {
  vertical-align: middle;
}

/* Rules for sizing the icon. */
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-20 { font-size: 20px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

/* Rules for using icons as black on a light background. */
.material-icons.md-dark { color: rgba(0, 0, 0, 0.54); }
.material-icons.md-dark.md-inactive { color: rgba(0, 0, 0, 0.26); }

/* Rules for using icons as white on a dark background. */
.material-icons.md-light { color: rgba(255, 255, 255, 1); }
.material-icons.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }

::-webkit-scrollbar {
  width: 12px;
  padding: 0px 0px 0px 0px;
  margin-bottom: 10px;
}/* width */

/* Track */
::-webkit-scrollbar-track {  
  background: #cce5eb; 
  /* background: rgb(230,230,230); */
  border: none;
  margin-bottom: 0px;
  margin-top: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #66b0c2;
  /* background: rgb(200,200,200); */
  border: none;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #007a92;
  /* background: rgb(150, 150, 150); */
}

input {
  outline: none
}

